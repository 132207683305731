// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

.saveBTNChip:hover {
    cursor: pointer;
}
.date-picker-full-width {
    display: flex;
}

.placeholder-dnd {
    position: absolute;
    background-color: white;
    border-radius: 3px;
    border: dashed 1px blue;
    background-color: white;
}
.grey-color {
    color: rgb(170, 170, 170);
}

#form-file-upload {
    height: 30rem;
    width: 68rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

// .upload-button {
//     cursor: pointer;
//     padding: 0.25rem;
//     font-size: 1rem;
//     border: none;
//     font-family: 'Oswald', sans-serif;
//     background-color: transparent;
// }

// .upload-button:hover {
//     text-decoration-line: underline;
// }

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.details-text {
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        display: flex;
        // -webkit-line-clamp: 8;
        // -webkit-box-orient: vertical;
        overflow: visible;
        // text-overflow: ellipsis;
    }
}

@media print {
    @page {
        size: auto;
        // margin-left: 0;
        // margin-right: 0;
        // margin-top: 20px;
        // margin-bottom: 20px;
    }
    .logo-sharedoutput {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: flex-end;
        height: 40vh;
    }
    .header-sharedoutput {
        min-height: 45vh;
        margin: 0;
        margin-top: 40px;
        // margin-bottom: 20vh;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .logo-image {
        height: 150px;
        width: 160px;
    }
    .menu-sharedoutput {
        display: none;
    }

    .maincard-sharedoutput {
        // display: 'block';
        // height: 48vh;
        // max-height: 48vh;
        // min-height: 48vh;
    }
    .title-sharedoutput {
        padding-top: -10px;
        padding-bottom: -10px;
        margin-top: -10px;
        margin-bottom: -10px;
    }
    // .grid-sharedoutput {
    //     margin: 0 !important;
    // }
    .card-leftside-sharedoutput {
        h6 {
            font-size: 14px;
        }
    }
    .card-leftside-sharedoutput {
        h6 {
            font-size: 14px;
        }
    }
}
